export enum TenantType {
  CUSTOMER = "Customer",
  LEARN = "Learn",
  REACH = "Reach",
  RAISE = "Raise",
  STUDENT = "Student",
  LEARN_INSTANCE = "LearnInstance",
  RELEASE_STAGE = "ReleaseStage",
  ILLUMINATE = "Illuminate",
  LEARN_DMS = "LearnDms",
}
