import { JobServiceException } from "../JobServiceException";

export class InvalidClaimException extends JobServiceException {
  constructor(claimId: string) {
    super(403, "InvalidClaimException", `Invalid claimId: ${claimId}`);
  }

  public static assertClaimId(
    execution: any,
    claimId?: string,
  ): asserts execution is ClaimedExecution {
    if (!claimId) {
      throw new InvalidClaimException("ClaimId is required");
    }
    if (!execution || !execution["_claimId"]) {
      // This is a bug in the server code, not a user error
      throw new Error("ClaimID missing in execution");
    }
    if (execution._claimId !== claimId) {
      throw new InvalidClaimException(claimId);
    }
  }
}

export type ClaimedExecution = {
  readonly _claimId: string;
};
