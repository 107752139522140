export enum Status {
  /**
   * This is the initial state of a job.
   * The job service is preparing to send the execution to the registered job provider
   * @type {Status.PENDING}
   */
  PENDING = "PENDING",
  /**
   * The job service has created a record of the execution and sent the request on to the service provider for processing.
   * If the service provider rejects the request, the job will immediately transition to the FAILED state.
   * @type {Status.QUEUED}
   */
  QUEUED = "QUEUED",
  /**
   * Once the implementing service begins to work on the job, it should send a message back to the job service transitioning it into this state
   * When sending an IN_PROGRESS message, data may be added to the output field in the same message
   * You may send an unlimited number of IN_PROGRESS messages as the execution progresses. This is useful to add additional data to the output, even while the task is still in progress.
   * @type {Status.IN_PROGRESS}
   */
  IN_PROGRESS = "IN_PROGRESS",
  /**
   * This is the success state. At this point the output of the task should match the schema definition.
   * Once an execution notifies the job service that it has transitioned to COMPLETED it may not update it's output again.
   * Any request to update a COMPLETED task will be rejected
   * @type {Status.COMPLETED}
   */
  COMPLETED = "COMPLETED",

  /**
   * The job service has received a request to cancel the job.
   * This is an internal state and will not be used outside the job service itself.
   * Once the job service receives an execution in this state, it will update the status to CANCELLATION_REQUESTED and fire a message to the job provider.
   * @type {Status.CANCELLATION_PENDING}
   */
  CANCELLATION_PENDING = "CANCELLATION_PENDING",
  /**
   * Cancellation has been requested for the job. The job provider should stop processing the job and transition to the CANCELED state.
   * @type {Status.CANCELLATION_REQUESTED}
   */
  CANCELLATION_REQUESTED = "CANCELLATION_REQUESTED",

  /**
   * The job provider failed to respond to the cancellation request. The behavior of the job provider in this state is undefined.
   * @type {Status.CANCELLATION_FAILED}
   */
  CANCELLATION_FAILED = "CANCELLATION_FAILED",

  /**
   * The job provider has successfully transitioned the job to the CANCELED state.
   * Output may be included in the message.
   * Once a job reaches this state it may not be updated.
   * @type {Status.CANCELED}
   */
  CANCELED = "CANCELED",
  /**
   * This is the failure state. It indicates that the job has completed unsuccessfully
   * Jobs should do their own error handling. Catch your executions, wind down execution, and notify the job service that the task has transitioned to failure.
   * Like the completed status, once a job reaches this state it may not be updated.
   * Make sure to include all output (including whatever error messages you wish to display) in the output when you send a FAILED message
   * @type {Status.FAILED}
   */
  FAILED = "FAILED",
  /**
   * This is an error state. It indicates that the job provider returned an invalid response to the job service.
   * This means the job is not implemented correctly; It is returning output (including error) that does not match the schema definition.
   * @type {Status.INVALID_PROVIDER_RESPONSE}
   */
  INVALID_PROVIDER_RESPONSE = "INVALID_PROVIDER_RESPONSE",
  /**
   *   This is the error state. It indicates an error was encountered within the job service itself.
   *   This state is uncommon. Errors encountered by job providers will result in a failed state.
   * @type {Status.INTERNAL_ERROR}
   */
  INTERNAL_ERROR = "INTERNAL_ERROR",

  /**
   * This is an error state. It indicates that the job provider took too long to respond to the job service.
   * The job service will wait for a response from the job provider with a reason for the failure before transitioning to the EXECUTION_TIMEOUT state.
   * @type {Status.EXECUTION_TIMEOUT_PENDING}
   */
  EXECUTION_TIMEOUT_PENDING = "EXECUTION_TIMEOUT_PENDING",

  /**
   * This is an error state. It indicates that the job provider took too long to respond to the job service.
   * This state should be avoided by job providers. If a job provider is unable to respond in a timely manner, it should transition to the FAILED state.
   * @type {Status.EXECUTION_TIMEOUT}
   */
  EXECUTION_TIMEOUT = "EXECUTION_TIMEOUT",

  /**
   * This is an error state. It indicates that no job provider was available to process the job.
   * @type {Status.QUEUE_TIMEOUT}
   */
  QUEUE_TIMEOUT = "QUEUE_TIMEOUT",
}

export type PendingStatus = (typeof PENDING_STATUSES)[number];
export const PENDING_STATUSES = [Status.PENDING, Status.QUEUED] as const;

export type ActiveStatus = (typeof ACTIVE_STATUSES)[number];
export const ACTIVE_STATUSES = [
  Status.IN_PROGRESS,
  Status.EXECUTION_TIMEOUT_PENDING,
  Status.CANCELLATION_PENDING,
  Status.CANCELLATION_REQUESTED,
] as const;

export type TerminalStatus = (typeof TERMINAL_STATUSES)[number];
export const TERMINAL_STATUSES = [
  Status.COMPLETED,
  Status.CANCELED,
  Status.FAILED,
  Status.INVALID_PROVIDER_RESPONSE,
  Status.INTERNAL_ERROR,
  Status.CANCELLATION_FAILED,
  Status.EXECUTION_TIMEOUT,
  Status.QUEUE_TIMEOUT,
] as const;
