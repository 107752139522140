import { JobServiceException } from "../JobServiceException";
import { Status } from "../../model/execution/Status";
import { JobExecution } from "../../model/execution/JobExecution";

export class InvalidExecutionStatusException extends JobServiceException {
  public constructor(
    executionId: string,
    tenantId: string,
    expectedStatus: Status[],
    actualStatus?: Status,
    additionalData?: object,
  ) {
    super(
      400,
      "InvalidExecutionStatusException",
      `Execution is in an invalid status for the requested action`,
      {
        ...additionalData,
        executionId,
        tenantId,
        expectedStatus,
        actualStatus,
      },
    );
  }

  public static assertStatus(
    execution: JobExecution,
    expectedStatus: Status[],
  ): asserts execution is JobExecutionOnStatus<typeof expectedStatus> {
    if (!expectedStatus.includes(execution.status)) {
      throw new InvalidExecutionStatusException(
        execution.executionId,
        execution.tenantId,
        expectedStatus,
        execution.status,
      );
    }
  }
}

export type JobExecutionOnStatus<Statuses extends Status[]> = JobExecution & {
  status: Statuses[number];
};
