import { JobServiceException } from "../JobServiceException";

export class InvalidTenantStateException extends JobServiceException {
  constructor(additionalData?: object) {
    super(
      409,
      "InvalidTenantStateException",
      "The target tenant is not in a valid state for the requested action",
      additionalData
    );
  }
}
