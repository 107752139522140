import { IForgeStack } from "./IForgeStack";

export class ServiceConfig {
  public static readonly SERVICE_NAME = "lct-jobs";

  // handles special cases for stage
  protected getStage(stage: string, deployment?: string): string {
    switch (stage) {
      case "proto":
        return "dev";
      case "local":
        return deployment ? "local" : "dev";
      default:
        return stage;
    }
  }

  /**
   * Get the account the job service is in based off the stack the client is in
   * @param {IForgeStack} stack
   * @returns {string}
   */
  public getAccount(stack: IForgeStack): string {
    if (stack.isIL4) {
      // in il4, the Job Service is ALWAYS in the same account as the Job Provider
      return stack.account;
    }
    switch (stack.stage) {
      case "local":
      case "dev":
        if (stack.isGovCloud) {
          // Learn Cloud Tooling Dev
          return "211714916788";
        }
        // Learn Cloud Tooling Dev
        return "764536937027";
      case "proto":
      case "int":
        if (stack.isGovCloud) {
          throw new Error("GovCloud is not supported for this stage");
        }
        // Learn Cloud Tooling Dev
        return "764536937027";
      case "tp":
      case "prod":
        if (stack.isGovCloud) {
          // Learn Cloud Tooling Prod GovCloud
          return "103329818094";
        }
        // Learn Cloud Tooling Prod Account
        return "453805273223";
      default:
        throw new Error(`Unknown stage: ${stack.stage}`);
    }
  }
}
